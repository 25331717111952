import React from 'react';

type Props = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    id: string;
    name: string;
    type: string;
    value: string;
    disabled: boolean;
    required: boolean;
    className?: string;
};

const FormInput: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <input
            className={`px-6 py-4 rounded-full border border-gray-200 hover:border-gray-300 focus:border-gray-300 outline-none transition duration-300`}
            type={props.type}
            name={props.name}
            id={props.id}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onChange(event)
            }
            disabled={props.disabled}
            required={props.required}
        />
    );
};

export default FormInput;
