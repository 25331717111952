import * as datapointService from '../../../../services/datapoint';

import React, { useContext, useEffect, useState } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router';

import Button from '../../../button';
import DataField from '../../../dataField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from '../../../formInput';
import { PulseLoader } from 'react-spinners';
import SkeletonLoader from '../../../skeletonLoader';
import { TenantContext } from '../../../../providers/tenantProvider';
import { auth } from '../../../../services/firebase';

type Props = unknown;

const EditDatapoint: React.FunctionComponent<Props> = () => {
    const tenant = useContext(TenantContext);
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const { typeId, entityId, datapointId } = useParams<{
        typeId: string;
        entityId: string;
        datapointId: string;
    }>();

    const [datapoint, setDatapoint] = useState<datapointService.Datapoint>({
        type: typeId,
        entity: entityId,
        createdByFormId: 1,
        longData: [],
        date: new Date(),
    });

    const entityType = tenant?.entities.find((entity) => entity._id === typeId);

    useEffect(() => {
        if (!entityType) return;

        const asyncFunc = async () => {
            const token = await auth.currentUser?.getIdToken();

            if (!token) return;

            const res = await datapointService.getDatapoint(
                datapointId,
                entityId,
                token,
            );

            if (!res) return;

            res.date = new Date(res.date);

            setDatapoint(res);

            setInitialLoading(false);
        };

        asyncFunc();
    }, []);

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        if (!datapoint) return;

        const { name, value } = event.currentTarget || event.target;

        const fieldIndex = datapoint.longData?.findIndex(
            (data) => data.variable === name,
        );

        const newDatapoint = { ...datapoint };

        if (fieldIndex !== -1) {
            newDatapoint.longData[fieldIndex].value = value;
        } else {
            newDatapoint.longData.push({ variable: name, value: value });
        }

        setDatapoint(newDatapoint);
    };

    const dateChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        if (!datapoint) return;

        const { value } = event.currentTarget || event.target;

        let newDate = datapoint.date;

        try {
            newDate = new Date(value);
        } catch (e) {
            null;
        }

        const newDatapoint = { ...datapoint, date: newDate };

        setDatapoint(newDatapoint);
    };

    const onSubmit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        datapoint: datapointService.Datapoint | undefined,
    ) => {
        if (!datapoint) return;

        setLoading(true);

        auth.currentUser
            ?.getIdToken()
            .then((token) =>
                datapointService.updateDatapoint(
                    datapointId,
                    entityId,
                    datapoint,
                    token,
                ),
            )
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <main className="container flex flex-col px-4 pt-16 mx-auto mb-8 space-y-8 bg-white">
            <div className="flex flex-row items-center">
                <h1 className="flex-grow text-4xl">{`Edit Datapoint - ${
                    entityType?.forms[datapoint.createdByFormId]?.name
                }`}</h1>
            </div>
            {initialLoading ? (
                <>
                    <div className="grid grid-cols-2 gap-8">
                        {[...Array(6)].map((val, index) => (
                            <SkeletonLoader
                                key={index}
                                className="h-16 rounded-full"
                            ></SkeletonLoader>
                        ))}
                    </div>
                    <div className="flex flex-row items-center justify-end space-x-4">
                        <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                        <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                    </div>
                </>
            ) : (
                <>
                    <form>
                        <div className="mb-8">
                            <h2 className="mb-4 text-2xl">Date</h2>
                            <FormInput
                                name="date"
                                id="date"
                                value={datapoint?.date
                                    .toISOString()
                                    .slice(0, 10)}
                                placeholder="Date"
                                onChange={(
                                    event: React.ChangeEvent<
                                        HTMLInputElement | HTMLSelectElement
                                    >,
                                ) => dateChangeHandler(event)}
                                disabled={loading}
                                type="date"
                                required
                            />
                        </div>
                        {entityType?.forms[
                            datapoint.createdByFormId
                        ]?.sections.map((formSection, sectionIndex) => {
                            return (
                                <div
                                    key={sectionIndex}
                                    className="pb-8 mb-12 border-b"
                                >
                                    <h2 className="mb-4 text-2xl">
                                        {formSection.name}
                                    </h2>
                                    <div className="grid grid-cols-2 gap-8">
                                        {formSection.fields.map(
                                            (field, fieldIndex) => {
                                                const variableType = entityType?.longData.find(
                                                    (vType) =>
                                                        vType.variable ==
                                                        field.variable,
                                                );
                                                return (
                                                    <div
                                                        className="flex flex-col"
                                                        key={fieldIndex}
                                                    >
                                                        <label
                                                            htmlFor={
                                                                variableType?.variable
                                                            }
                                                            className="mb-2 text-sm"
                                                        >
                                                            {
                                                                variableType?.label
                                                            }{' '}
                                                            {variableType?.units && (
                                                                <i className="text-xs">
                                                                    (
                                                                    {
                                                                        variableType?.units
                                                                    }
                                                                    )
                                                                </i>
                                                            )}
                                                        </label>
                                                        <DataField
                                                            name={
                                                                variableType?.variable ||
                                                                ''
                                                            }
                                                            id={
                                                                variableType?.variable ||
                                                                ''
                                                            }
                                                            value={
                                                                datapoint?.longData.find(
                                                                    (data) =>
                                                                        data.variable ===
                                                                        variableType?.variable,
                                                                )?.value || ''
                                                            }
                                                            placeholder={
                                                                variableType?.label ||
                                                                ''
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<
                                                                    | HTMLInputElement
                                                                    | HTMLSelectElement
                                                                >,
                                                            ) =>
                                                                onChangeHandler(
                                                                    event,
                                                                )
                                                            }
                                                            disabled={loading}
                                                            dataType={
                                                                variableType?.type ||
                                                                ''
                                                            }
                                                            typeOptions={
                                                                variableType?.options ||
                                                                []
                                                            }
                                                        />
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </form>
                    <div className="flex flex-row items-center justify-end space-x-4">
                        <Button
                            onClick={() => {
                                history.goBack();
                            }}
                            type="button"
                            className=""
                            colorClass="red-600"
                            hoverColorClass="red-700"
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="text-md"
                            ></FontAwesomeIcon>
                            <span>Cancel</span>
                        </Button>
                        <Button
                            onClick={(
                                event: React.MouseEvent<
                                    HTMLButtonElement,
                                    MouseEvent
                                >,
                            ) => {
                                onSubmit(event, datapoint);
                            }}
                            type="button"
                            className=""
                        >
                            {!loading && !initialLoading ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-sm"
                                    ></FontAwesomeIcon>
                                    <span>Update Datapoint</span>
                                </>
                            ) : (
                                <PulseLoader
                                    color="white"
                                    size={11}
                                    margin={4}
                                ></PulseLoader>
                            )}
                        </Button>
                    </div>
                </>
            )}
        </main>
    );
};

export default EditDatapoint;
