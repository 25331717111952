import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import FormInput from '../formInput';

type Props = {
    onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    placeholder: string;
    id: string;
    name: string;
    value: string;
    disabled: boolean;
    required?: boolean;
    className?: string;
    dataType: string;
    typeOptions?: string[];
    calculation?: string;
    calculationVariables?: { [key: string]: unknown };
};

const DataField: React.FunctionComponent<Props> = (props: Props) => {
    switch (props.dataType) {
        case 'text':
            return (
                <FormInput
                    type="text"
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.onChange(event)
                    }
                    disabled={props.disabled}
                    required={props.required || false}
                />
            );
            break;
        case 'number':
            return (
                <FormInput
                    type="number"
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.onChange(event)
                    }
                    disabled={props.disabled}
                    required={props.required || false}
                />
            );
            break;
        case 'enum':
            return (
                <div className="w-full">
                    <select
                        value={props.value}
                        onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>,
                        ) => props.onChange(event)}
                        id={props.id}
                        name={props.name}
                        disabled={props.disabled}
                        className="w-full rounded-full px-6 py-4 bg-white border hover:border-gray-300 focus:border-gray-300 outline-none transition duration-300 appearance-none"
                    >
                        <option hidden disabled></option>
                        {(props.typeOptions || []).map((value, key) => (
                            <option key={key} value={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                    <span className="-ml-10 text-gray-400 pointer-events-none">
                        <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
                    </span>
                </div>
            );
            break;
        case 'date':
            return (
                <FormInput
                    type="date"
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.onChange(event)
                    }
                    disabled={props.disabled}
                    required={props.required || false}
                />
            );
            break;
        default:
            return <div></div>;
            break;
    }
};

export default DataField;
