import React, { useContext } from 'react';
import { UserContext } from '../../../providers/userProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faTrashAlt,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { TenantContext } from '../../../providers/tenantProvider';
import { User } from '../../../services/users';

type Props = {
    users: Array<User>;
};

const UserList: React.FunctionComponent<Props> = (props: Props) => {
    const authedUser = useContext(UserContext);
    const tenant = useContext(TenantContext);

    if (!props.users || props.users.length === 0)
        return (
            <p className="mt-12 text-center italic">
                It looks like there&lsquo;s been an error. Please contact
                support.
            </p>
        );

    return (
        <article className="">
            <table className="table-fixed w-full text-left border-b">
                <thead>
                    <tr className="border-b">
                        <th className="w-1/12 p-4"></th>
                        <th className="w-4/12 p-4">Name</th>
                        <th className="w-4/12 p-4">Email</th>
                        <th className="w-2/12 p-4">Role</th>
                        <th className="w-2/12 p-4"></th>
                    </tr>
                </thead>
                <tbody className="">
                    {props.users.map((user, key) => {
                        const currentUser = user.id === authedUser?.uid;
                        return (
                            <tr
                                className="hover:bg-gray-50 transition duration-300 leading-5"
                                key={key}
                            >
                                <td>
                                    <div className="w-12 h-12 flex flex-row items-center justify-center text-xl text-gray-300">
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                </td>
                                <td className="p-4">
                                    {user.displayName || 'Anonymous User'}
                                </td>
                                <td className="p-4">{user.email}</td>
                                <td className="p-4">
                                    <span
                                        className={`bg-${tenant?.colorScheme.mid} text-white font-bold text-sm px-2 py-1 rounded-full`}
                                    >
                                        {user.role}
                                    </span>
                                </td>
                                <td className="flex flex-row justify-end space-x-2 p-4">
                                    <Link
                                        to={`/users/${user.id}`}
                                        className={
                                            currentUser
                                                ? 'pointer-events-none'
                                                : ''
                                        }
                                    >
                                        <div
                                            className={`rounded-full w-8 h-8 flex flex-row items-center justify-center text-sm text-${
                                                currentUser
                                                    ? 'gray-400'
                                                    : tenant?.colorScheme.mid
                                            } hover:text-${
                                                tenant?.colorScheme.dark
                                            } hover:bg-white hover:shadow-lg transition duration-300`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                            />
                                        </div>
                                    </Link>
                                    <Link
                                        to={`/users/${user.id}/delete`}
                                        className={
                                            currentUser
                                                ? 'pointer-events-none'
                                                : ''
                                        }
                                    >
                                        <div
                                            className={`rounded-full w-8 h-8 flex flex-row items-center justify-center text-sm text-${
                                                currentUser
                                                    ? 'gray-400'
                                                    : tenant?.colorScheme.mid
                                            } hover:text-${
                                                tenant?.colorScheme.dark
                                            } hover:bg-white hover:shadow-lg transition duration-300`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                            />
                                        </div>
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </article>
    );
};

export default UserList;
