import React, { useContext, useState } from 'react';
import Firebase from 'firebase';
import PulseLoader from 'react-spinners/PulseLoader';

import { auth } from '../../services/firebase';
import { TenantContext } from '../../providers/tenantProvider';

const SignIn: React.FunctionComponent = () => {
    const tenant = useContext(TenantContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // Use the firebase service to get the auth object and sign in
    const signInWithEmailAndPasswordHandler = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        email: string,
        password: string,
    ) => {
        event.preventDefault();

        // Don't attempt sign-in if we are already waiting...
        if (loading) return;

        // Attempt sign-in
        setLoading(true);
        await auth
            .signInWithEmailAndPassword(email, password)
            .catch((error: Firebase.auth.Error) => {
                setError(error.message);
                setLoading(false);
            });
    };

    // Update state to reflect form field values
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        } else if (name === 'userPassword') {
            setPassword(value);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen mx-4">
            <div className="flex flex-col max-w-md w-full items-stretch shadow-xl hover:shadow-2xl transition duration-300 rounded-3xl p-8">
                <h1
                    className={`text-4xl text-${
                        tenant?.colorScheme.mid || ''
                    } text-center mt-8 mb-16`}
                >
                    {tenant?.name}
                </h1>
                <form
                    action=""
                    className="flex flex-col space-y-4 items-stretch"
                >
                    <input
                        className="px-6 py-4 rounded-full border border-gray-200 hover:border-gray-300 focus:border-gray-300 outline-none transition duration-300 text-center"
                        type="email"
                        name="userEmail"
                        id="userEmail"
                        value={email}
                        placeholder="Email"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                    />
                    <input
                        className="px-6 py-4 rounded-full border border-gray-200 hover:border-gray-300 focus:border-gray-300 outline-none transition duration-300 text-center"
                        type="password"
                        name="userPassword"
                        id="userPassword"
                        value={password}
                        placeholder="Password"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                    />
                    <p
                        hidden={!error}
                        className="text-sm text-center text-red-700"
                    >
                        {error}
                    </p>
                    <button
                        className={`px-6 py-4 rounded-full bg-${
                            tenant?.colorScheme.mid || 'indigo-600'
                        } text-white font-bold hover:bg-${
                            tenant?.colorScheme.dark || 'indigo-700'
                        } hover:shadow-md transition duration-300 focus:outline-none`}
                        type="submit"
                        onClick={(
                            event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                            >,
                        ) =>
                            signInWithEmailAndPasswordHandler(
                                event,
                                email,
                                password,
                            )
                        }
                    >
                        {!loading ? (
                            'Sign In'
                        ) : (
                            <PulseLoader
                                color="white"
                                size={11}
                                margin={4}
                            ></PulseLoader>
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
