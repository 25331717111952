import * as QueryString from 'query-string';
import * as datapointService from '../../../../services/datapoint';
import * as math from 'mathjs';

import React, { useContext, useEffect, useState } from 'react';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router';

import Button from '../../../button';
import DataField from '../../../dataField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from '../../../formInput';
import { PulseLoader } from 'react-spinners';
import SkeletonLoader from '../../../skeletonLoader';
import { TenantContext } from '../../../../providers/tenantProvider';
import { auth } from '../../../../services/firebase';
import { useLocation } from 'react-router-dom';

type Props = unknown;

const CreateDatapoint: React.FunctionComponent<Props> = () => {
    const tenant = useContext(TenantContext);
    const history = useHistory();
    const location = useLocation();

    const { formIndex } = QueryString.parse(location.search);

    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const { typeId, entityId } = useParams<{
        typeId: string;
        entityId: string;
    }>();

    const [datapoint, setDatapoint] = useState<datapointService.Datapoint>({
        type: typeId,
        entity: entityId,
        longData: [],
        createdByFormId: parseInt(formIndex?.toString() || '0'),
        date: new Date(),
    });

    const entityType = tenant?.entities.find((entity) => entity._id === typeId);

    const form = entityType?.forms[parseInt(formIndex?.toString() || '0')];

    const dependencies = form?.sections.flatMap((section) =>
        section.fields.flatMap((field) => field.dependencies || []),
    );

    useEffect(() => {
        if (!entityType) return;

        const initDate = new Date();

        initDate.setHours(0, 0, 0, 0);

        const newDatapoint: datapointService.Datapoint = {
            date: initDate,
            entity: entityId,
            type: typeId,
            createdByFormId: parseInt(formIndex?.toString() || '0'),
            longData: [],
        };

        setDatapoint(newDatapoint);
        setInitialLoading(false);
    }, []);

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        if (!datapoint) return;

        const { name, value } = event.currentTarget || event.target;

        const fieldIndex = datapoint.longData?.findIndex(
            (data) => data.variable === name,
        );

        const newDatapoint = { ...datapoint };

        if (fieldIndex !== -1) {
            newDatapoint.longData[fieldIndex].value = value;
        } else {
            newDatapoint.longData.push({ variable: name, value: value });
        }

        if (dependencies?.includes(name)) {
            form?.sections.map((section) =>
                section.fields.map((field) => {
                    if (
                        field.calculation &&
                        field.dependencies?.every((dependency) =>
                            datapoint.longData.find(
                                (data) => data.variable === dependency,
                            ),
                        )
                    ) {
                        try {
                            const scope = Object.fromEntries(
                                datapoint.longData.map((data) => [
                                    data.variable,
                                    data.value,
                                ]),
                            );

                            if (
                                field.dependencies.every((dependency) =>
                                    datapoint.longData.find(
                                        (data) =>
                                            data.variable === dependency &&
                                            data.value,
                                    ),
                                )
                            ) {
                                const calculation = math.evaluate(
                                    field.calculation,
                                    scope,
                                );

                                const calculatedValue =
                                    typeof calculation === 'number'
                                        ? calculation
                                        : calculation.entries[0];

                                const calculatedFieldIndex = datapoint.longData?.findIndex(
                                    (data) => data.variable === field.variable,
                                );

                                if (fieldIndex !== -1) {
                                    newDatapoint.longData[
                                        calculatedFieldIndex
                                    ].value =
                                        typeof calculatedValue === 'number'
                                            ? math
                                                  .round(calculatedValue, 3)
                                                  .toString()
                                            : calculatedValue;
                                } else {
                                    newDatapoint.longData.push({
                                        variable: field.variable,
                                        value:
                                            typeof calculatedValue === 'number'
                                                ? math
                                                      .round(calculatedValue, 3)
                                                      .toString()
                                                : calculatedValue,
                                    });
                                }
                            } else {
                                const calculatedFieldIndex = datapoint.longData?.findIndex(
                                    (data) => data.variable === field.variable,
                                );

                                if (fieldIndex !== -1) {
                                    newDatapoint.longData[
                                        calculatedFieldIndex
                                    ].value = undefined;
                                }
                            }
                        } catch (error) {
                            console.error(error);
                            null;
                        }
                    }
                }),
            );
        }

        setDatapoint(newDatapoint);
    };

    const dateChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        if (!datapoint) return;

        const { value } = event.currentTarget || event.target;

        let newDate = datapoint.date;

        try {
            newDate = new Date(value);
        } catch (e) {
            null;
        }

        const newDatapoint = { ...datapoint, date: newDate };

        setDatapoint(newDatapoint);
    };

    const onSubmit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        datapoint: datapointService.Datapoint | undefined,
    ) => {
        if (!datapoint) return;

        datapoint.longData = datapoint.longData.filter((data) => data.value);

        setLoading(true);

        auth.currentUser
            ?.getIdToken()
            .then((token) =>
                datapointService.createDatapoint(entityId, datapoint, token),
            )
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <main className="container flex flex-col px-4 pt-16 mx-auto mb-8 space-y-8 bg-white">
            <div className="flex flex-row items-center">
                <h1 className="flex-grow text-4xl">{`Create Datapoint - ${form?.name}`}</h1>
            </div>
            {initialLoading ? (
                <>
                    <div className="grid grid-cols-2 gap-8">
                        {[...Array(6)].map((val, index) => (
                            <SkeletonLoader
                                key={index}
                                className="h-16 rounded-full"
                            ></SkeletonLoader>
                        ))}
                    </div>
                    <div className="flex flex-row items-center justify-end space-x-4">
                        <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                        <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                    </div>
                </>
            ) : (
                <>
                    <form>
                        <div className="mb-8">
                            <h2 className="mb-4 text-2xl">Date</h2>
                            <FormInput
                                name="date"
                                id="date"
                                value={datapoint?.date
                                    .toISOString()
                                    .slice(0, 10)}
                                placeholder="Date"
                                onChange={(
                                    event: React.ChangeEvent<
                                        HTMLInputElement | HTMLSelectElement
                                    >,
                                ) => dateChangeHandler(event)}
                                disabled={loading}
                                type="date"
                                required
                            />
                        </div>
                        {form?.sections.map((formSection, sectionIndex) => {
                            return (
                                <div
                                    key={sectionIndex}
                                    className="pb-8 mb-12 border-b"
                                >
                                    <h2 className="mb-4 text-2xl">
                                        {formSection.name}
                                    </h2>
                                    <div className="grid grid-cols-2 gap-8">
                                        {formSection.fields.map(
                                            (field, fieldIndex) => {
                                                const variableType = entityType?.longData.find(
                                                    (vType) =>
                                                        vType.variable ==
                                                        field.variable,
                                                );
                                                return (
                                                    <div
                                                        className="flex flex-col"
                                                        key={fieldIndex}
                                                    >
                                                        <label
                                                            htmlFor={
                                                                variableType?.variable
                                                            }
                                                            className="mb-2 text-sm"
                                                        >
                                                            {
                                                                variableType?.label
                                                            }{' '}
                                                            {variableType?.units && (
                                                                <i className="text-xs">
                                                                    (
                                                                    {
                                                                        variableType?.units
                                                                    }
                                                                    )
                                                                </i>
                                                            )}
                                                        </label>
                                                        <DataField
                                                            name={
                                                                variableType?.variable ||
                                                                ''
                                                            }
                                                            id={
                                                                variableType?.variable ||
                                                                ''
                                                            }
                                                            value={
                                                                datapoint?.longData.find(
                                                                    (data) =>
                                                                        data.variable ===
                                                                        variableType?.variable,
                                                                )?.value || ''
                                                            }
                                                            placeholder={
                                                                variableType?.label ||
                                                                ''
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<
                                                                    | HTMLInputElement
                                                                    | HTMLSelectElement
                                                                >,
                                                            ) =>
                                                                onChangeHandler(
                                                                    event,
                                                                )
                                                            }
                                                            disabled={
                                                                loading ||
                                                                Boolean(
                                                                    field.calculation,
                                                                )
                                                            }
                                                            dataType={
                                                                variableType?.type ||
                                                                ''
                                                            }
                                                            typeOptions={
                                                                variableType?.options ||
                                                                []
                                                            }
                                                        />
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </form>
                    <div className="flex flex-row items-center justify-end space-x-4">
                        <Button
                            onClick={() => {
                                history.goBack();
                            }}
                            type="button"
                            className=""
                            colorClass="red-600"
                            hoverColorClass="red-700"
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="text-md"
                            ></FontAwesomeIcon>
                            <span>Cancel</span>
                        </Button>
                        <Button
                            onClick={(
                                event: React.MouseEvent<
                                    HTMLButtonElement,
                                    MouseEvent
                                >,
                            ) => {
                                onSubmit(event, datapoint);
                            }}
                            type="button"
                            className=""
                        >
                            {!loading && !initialLoading ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="text-sm"
                                    ></FontAwesomeIcon>
                                    <span>Create Datapoint</span>
                                </>
                            ) : (
                                <PulseLoader
                                    color="white"
                                    size={11}
                                    margin={4}
                                ></PulseLoader>
                            )}
                        </Button>
                    </div>
                </>
            )}
        </main>
    );
};

export default CreateDatapoint;
