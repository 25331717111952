import axios from 'axios';

import apiRoot from './apiRoot';

const apiUrl = `${apiRoot}/users`;

export interface User {
    id?: string;
    email: string;
    role: string;
    displayName: string;
}

export interface CreateUser extends User {
    password: string;
}

const getUser = async (userId: string, token: string): Promise<User | null> => {
    try {
        const res = await axios.get(`${apiUrl}/${userId}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return res.data;
    } catch (error) {
        return null;
    }
};

const getUsers = async (token: string): Promise<User[] | null> => {
    try {
        const res = await axios.get(`${apiUrl}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return res.data;
    } catch (error) {
        return null;
    }
};

const deleteUser = async (
    userId: string,
    token: string,
): Promise<true | null> => {
    try {
        await axios.delete(`${apiUrl}/${userId}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return true;
    } catch (error) {
        return null;
    }
};

const createUser = async (
    user: CreateUser,
    token: string,
): Promise<true | null> => {
    try {
        await axios.post(
            `${apiUrl}`,
            {
                ...user,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        );
        return true;
    } catch (error) {
        return null;
    }
};

const updateUser = async (
    userId: string,
    user: Partial<CreateUser>,
    token: string,
): Promise<true | null> => {
    try {
        await axios.patch(
            `${apiUrl}/${userId}`,
            {
                ...user,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        );
        return true;
    } catch (error) {
        return null;
    }
};

export { getUser, getUsers, createUser, deleteUser, updateUser };
