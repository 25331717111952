import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router';
import { signOut } from '../../../services/firebase';
import Button from '../../button';

type Props = unknown;

const SignOut: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const signOutUser = async () => {
        await signOut();
        history.push('/');
    };

    return (
        <div className="flex flex-col space-y-4 items-center p-8 rounded-3xl shadow-xl hover:shadow-2xl transition duration-300">
            <h2 className="text-2xl">Sign Out</h2>
            <p>Click here to sign out of your account.</p>
            <Button
                colorClass="red-600"
                hoverColorClass="red-700"
                onClick={() => signOutUser()}
            >
                <FontAwesomeIcon
                    icon={faLock}
                    className="text-sm"
                ></FontAwesomeIcon>
                <span>Sign Out</span>
            </Button>
        </div>
    );
};

export default SignOut;
