import React, { useEffect, useState } from 'react';
import { getUsers, User } from '../../services/users';
import UserList from './userList';
import SkeletonLoader from '../skeletonLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ButtonLink from '../buttonLink';
import { auth } from '../../services/firebase';

type Props = unknown;

const Users: React.FunctionComponent<Props> = () => {
    const [users, setUsers] = useState<Array<User>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        auth.currentUser
            ?.getIdToken(true)
            .then((token) => getUsers(token))
            .then((res) => {
                setUsers(res || []);
                console.log(res);
            })
            .then(() => setLoading(false));
    }, []);

    return (
        <main className="container px-4 mx-auto flex flex-col bg-white pt-16 space-y-8 mb-8">
            {loading ? (
                <>
                    <div className="flex flex-row items-center space-x-4">
                        <h1 className="text-4xl flex-grow">Users</h1>
                        <SkeletonLoader className="h-16 w-40 rounded-full"></SkeletonLoader>
                    </div>
                    <div className="flex flex-col space-y-6">
                        {[...Array(3)].map((val, index) => (
                            <div
                                key={index}
                                className="bg-gray-200 h-12 rounded-full animate-pulse"
                            ></div>
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-row">
                        <h1 className="text-4xl flex-grow">Users</h1>
                        <ButtonLink
                            to="/users/create"
                            className={loading ? 'hidden' : ''}
                        >
                            <FontAwesomeIcon
                                icon={faPlus}
                                className="text-sm"
                            ></FontAwesomeIcon>
                            <span>New User</span>
                        </ButtonLink>
                    </div>

                    <UserList users={users || []}></UserList>
                </>
            )}
        </main>
    );
};

export default Users;
