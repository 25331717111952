import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from '../navbar/navbar';
import SignIn from '../signin';
import { UserContext } from '../../providers/userProvider';

import { routes } from './routes';
import { auth } from '../../services/firebase';
import Forbidden from '../forbidden';
import NotFound from '../notFound';
import { TenantContext } from '../../providers/tenantProvider';

type Props = unknown;

const App: React.FunctionComponent<Props> = () => {
    const user = useContext(UserContext);
    const tenant = useContext(TenantContext);

    const [userRole, setUserRole] = useState<string>('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdTokenResult().then((token) => {
                    setUserRole(token.claims.role);
                });
            }
        });
    }, []);

    const navbarRoutes = [];
    navbarRoutes.push({ label: 'Dashboard', path: '/', exact: true });
    if (tenant) {
        navbarRoutes.push(
            ...tenant?.entities.map((entity) => {
                return {
                    label: entity.pluralName,
                    path: `/entityType/${entity._id}`,
                    exact: false,
                };
            }),
        );
    }
    if (user && ['admin', 'manager'].includes(user.role)) {
        navbarRoutes.push({ label: 'Users', path: '/users', exact: false });
    }

    if (user) {
        return (
            <div className="">
                <Router>
                    <Navbar routes={navbarRoutes} logo="Registry" />
                    <Switch>
                        {routes.map((route, index) => (
                            <Route
                                path={route.path}
                                exact={route.exact}
                                key={index}
                            >
                                {!route.authorization?.roles ||
                                route.authorization.roles?.includes(
                                    userRole,
                                ) ? (
                                    route.component
                                ) : (
                                    <Forbidden></Forbidden>
                                )}
                            </Route>
                        ))}
                        <Route>
                            <NotFound></NotFound>
                        </Route>
                    </Switch>
                    <footer>
                        <div className="flex flex-row container px-4 mx-auto border-t py-2 justify-between text-xs text-gray-500 mb-4">
                            <span></span>
                            <span>&copy; Hepian Ltd - 2021</span>
                        </div>
                    </footer>
                </Router>
            </div>
        );
    } else {
        return <SignIn></SignIn>;
    }
};

export default App;
