import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { UserContext } from '../../../providers/userProvider';
import { auth } from '../../../services/firebase';
import Button from '../../button';
import FormInput from '../../formInput';

type Props = unknown;

const UserInfo: React.FunctionComponent<Props> = () => {
    const authedUser = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [email, setEmail] = useState<string>(authedUser?.email || '');
    const [displayName, setDisplayName] = useState<string>(
        authedUser?.displayName || '',
    );

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        } else if (name === 'userDisplayName') {
            setDisplayName(value);
        }
    };

    const onSubmit = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        email: string,
        displayName: string,
    ) => {
        if (!email || !displayName) {
            setError('Please make sure your email and name are filled in!');
            return;
        }

        setLoading(true);

        try {
            await auth.currentUser?.updateEmail(email);
            await auth.currentUser?.updateProfile({ displayName });
            await auth.currentUser?.getIdToken(true);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return (
        <div className="mb-24">
            <h2 className="text-2xl mb-4">Update Your Info</h2>
            <p className="mb-4">
                {`You can update your personal information below. This is the email you use to sign in.`}
            </p>
            {error ? <p className="text-red-600 mb-4">{error}</p> : <></>}
            <form action="" className="flex flex-row space-x-4 items-end">
                <div className="flex flex-col flex-grow flex-shrink">
                    <label htmlFor="userEmail" className="text-sm mb-2">
                        Email address:
                    </label>

                    <FormInput
                        type="email"
                        name="userEmail"
                        id="userEmail"
                        value={email}
                        placeholder="joe@example.com"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex flex-col flex-grow flex-shrink">
                    <label htmlFor="userDisplayName" className="text-sm mb-2">
                        Full name:
                    </label>

                    <FormInput
                        type="text"
                        name="userDisplayName"
                        id="userDisplayName"
                        value={displayName}
                        placeholder="Dr Joe Bloggs"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex-shrink-0">
                    <Button
                        onClick={(
                            event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                            >,
                        ) => {
                            onSubmit(event, email, displayName);
                        }}
                        type="button"
                        className=""
                    >
                        {!loading ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="text-sm"
                                ></FontAwesomeIcon>
                                <span>Update Info</span>
                            </>
                        ) : (
                            <PulseLoader
                                color="white"
                                size={11}
                                margin={4}
                            ></PulseLoader>
                        )}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default UserInfo;
