import React from 'react';

import UserProvider from '../../providers/userProvider';
import Application from '../app';
import TenantProvider from '../../providers/tenantProvider';

type Props = unknown;

const Wrapper: React.FunctionComponent<Props> = () => {
    return (
        <TenantProvider>
            <UserProvider>
                <Application></Application>
            </UserProvider>
        </TenantProvider>
    );
};

export default Wrapper;
