import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { auth, signOut } from '../../../services/firebase';
import { updateUser } from '../../../services/users';
import Button from '../../button';
import FormInput from '../../formInput';

type Props = unknown;

const Password: React.FunctionComponent<Props> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = event.currentTarget;

        if (name === 'userPassword') {
            setPassword(value);
        } else if (name === 'userConfirmPassword') {
            setConfirmPassword(value);
        }
    };

    const onSubmit = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        password: string,
        confirmPassword: string,
    ) => {
        if (!password || !confirmPassword) {
            setError('Please fill in both fields first!');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords must match!');
            return;
        }

        setLoading(true);

        try {
            await updateUser(
                auth.currentUser?.uid || '',
                { password },
                (await auth.currentUser?.getIdToken()) || '',
            );
            await signOut();
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <div className="mb-16">
            <h2 className="text-2xl mb-4">Reset Your Password</h2>
            <p className="mb-4">
                {`For added security, you'll need to sign back in once you've updated your password.`}
            </p>
            {error ? <p className="text-red-600 mb-4">{error}</p> : <></>}
            <form action="" className="flex flex-row space-x-4 items-end">
                <div className="flex flex-col flex-grow flex-shrink">
                    <label htmlFor="userPassword" className="text-sm mb-2">
                        Password:
                    </label>

                    <FormInput
                        type="password"
                        name="userPassword"
                        id="userPassword"
                        value={password}
                        placeholder="••••••••"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex flex-col flex-grow flex-shrink">
                    <label
                        htmlFor="userConfirmPassword"
                        className="text-sm mb-2"
                    >
                        Confirm password:
                    </label>

                    <FormInput
                        type="password"
                        name="userConfirmPassword"
                        id="userConfirmPassword"
                        value={confirmPassword}
                        placeholder="••••••••"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex-shrink-0">
                    <Button
                        onClick={(
                            event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                            >,
                        ) => {
                            onSubmit(event, password, confirmPassword);
                        }}
                        type="button"
                        className=""
                    >
                        {!loading ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="text-sm"
                                ></FontAwesomeIcon>
                                <span>Update Password</span>
                            </>
                        ) : (
                            <PulseLoader
                                color="white"
                                size={11}
                                margin={4}
                            ></PulseLoader>
                        )}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Password;
