import React, { useEffect, useState } from 'react';

import { getUser, updateUser, User, CreateUser } from '../../../services/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from '../../formInput';
import Button from '../../button';
import {
    faArrowDown,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';
import { useHistory, useParams } from 'react-router-dom';
import { auth } from '../../../services/firebase';

type Props = unknown;

const EditUser: React.FunctionComponent<Props> = () => {
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [user, setUser] = useState<User>({
        id: id,
        email: '',
        displayName: '',
        role: '',
    });

    const [originalUser, setOriginalUser] = useState<User>({
        id: id,
        email: '',
        displayName: '',
        role: '',
    });

    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const roleOptions = [
        {
            value: 'admin',
            label: 'Admin',
        },
        {
            value: 'manager',
            label: 'Manager',
        },
        {
            value: 'user',
            label: 'User',
        },
        {
            value: 'observer',
            label: 'Observer',
        },
    ];

    useEffect(() => {
        auth.currentUser
            ?.getIdToken()
            .then((token) => getUser(id, token))
            .then((userData) => {
                const newUser: User = {
                    ...user,
                    ...userData,
                };
                setUser({ ...newUser });
                setOriginalUser({ ...newUser });
            })
            .then(() => {
                setInitialLoading(false);
            });
    }, []);

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = event.currentTarget;

        if (name === 'userEmail') {
            setUser({ ...user, email: value });
        } else if (name === 'userName') {
            setUser({ ...user, displayName: value });
        } else if (name === 'userPassword') {
            setPassword(value);
        } else if (name === 'userConfirmPassword') {
            setConfirmPassword(value);
        } else if (event.target.name === 'userRole') {
            setUser({ ...user, role: event.target.value });
        }
    };

    const onSubmit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        email: string,
        displayName: string,
        password: string,
        confirmPassword: string,
        role: string,
    ) => {
        event.preventDefault();

        const update: Partial<CreateUser> = {};

        if (email !== originalUser.email) {
            if (!email) {
                setError('Please ensure that the email is valid.');
                return;
            }

            update.email = email;
        }

        if (displayName !== originalUser.displayName) {
            if (!displayName) {
                setError("Please ensure that the user's name is valid.");
                return;
            }

            update.displayName = displayName;
        }

        if (role !== originalUser.role) {
            if (!role) {
                setError("Please ensure that the user's role is valid.");
                return;
            }

            update.role = role;
        }

        if (password || confirmPassword) {
            if (password !== confirmPassword) {
                setError('Passwords must match!');
                return;
            }

            update.password = password;
        }

        setLoading(true);

        auth.currentUser
            ?.getIdToken()
            .then((token) => updateUser(id, update, token))
            .then(() => {
                setLoading(false);
                history.push('/users');
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return (
        <main className="container px-4 mx-auto flex flex-col bg-white pt-16 space-y-8 mb-8">
            <div className="flex flex-row">
                <h1 className="text-4xl flex-grow">Create User</h1>
            </div>
            <div
                className={`text-red-600 ${error && !loading ? '' : 'hidden'}`}
            >
                {error}
            </div>
            <form action="" className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="userEmail" className="text-sm mb-2">
                        Email address:
                    </label>
                    {initialLoading ? (
                        <div className="animate-pulse h-16 bg-gray-200 rounded-full"></div>
                    ) : (
                        <FormInput
                            type="email"
                            name="userEmail"
                            id="userEmail"
                            value={user.email}
                            placeholder="joe@example.com"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => onChangeHandler(event)}
                            disabled={loading}
                            required
                        />
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="userName" className="text-sm mb-2">
                        Full name:
                    </label>
                    {initialLoading ? (
                        <div className="animate-pulse h-16 bg-gray-200 rounded-full"></div>
                    ) : (
                        <FormInput
                            type="text"
                            name="userName"
                            id="userName"
                            value={user.displayName}
                            placeholder="Dr Joe Bloggs"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => onChangeHandler(event)}
                            disabled={loading}
                            required
                        />
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="userPassword" className="text-sm mb-2">
                        Password:
                    </label>
                    {initialLoading ? (
                        <div className="animate-pulse h-16 bg-gray-200 rounded-full"></div>
                    ) : (
                        <FormInput
                            type="password"
                            name="userPassword"
                            id="userPassword"
                            value={password}
                            placeholder="••••••••"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => onChangeHandler(event)}
                            disabled={loading}
                            required
                        />
                    )}
                </div>
                <div className="flex flex-col">
                    <label
                        htmlFor="userConfirmPassword"
                        className="text-sm mb-2"
                    >
                        Confirm password:
                    </label>
                    {initialLoading ? (
                        <div className="animate-pulse h-16 bg-gray-200 rounded-full"></div>
                    ) : (
                        <FormInput
                            type="password"
                            name="userConfirmPassword"
                            id="userConfirmPassword"
                            value={confirmPassword}
                            placeholder="••••••••"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => onChangeHandler(event)}
                            disabled={loading}
                            required
                        />
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="userRole" className="text-sm mb-2">
                        User account role:
                    </label>
                    {initialLoading ? (
                        <div className="animate-pulse h-16 bg-gray-200 rounded-full"></div>
                    ) : (
                        <div className="w-full">
                            <select
                                value={user.role}
                                onChange={(
                                    event: React.ChangeEvent<HTMLSelectElement>,
                                ) => onChangeHandler(event)}
                                id="userRole"
                                name="userRole"
                                disabled={loading}
                                className="w-full rounded-full px-6 py-4 bg-white border hover:border-gray-300 focus:border-gray-300 outline-none transition duration-300 appearance-none"
                            >
                                {roleOptions.map((value, key) => (
                                    <option key={key} value={value.value}>
                                        {value.label}
                                    </option>
                                ))}
                            </select>
                            <span className="-ml-10 text-gray-400 pointer-events-none">
                                <FontAwesomeIcon
                                    icon={faArrowDown}
                                ></FontAwesomeIcon>
                            </span>
                        </div>
                    )}
                </div>
                <div></div>
            </form>
            <div className="flex flex-row justify-end items-center space-x-4">
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                    type="button"
                    className=""
                    colorClass="red-600"
                    hoverColorClass="red-700"
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-md"
                    ></FontAwesomeIcon>
                    <span>Cancel</span>
                </Button>
                <Button
                    onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                        onSubmit(
                            event,
                            user.email,
                            user.displayName,
                            password,
                            confirmPassword,
                            user.role,
                        );
                    }}
                    type="button"
                    className=""
                >
                    {!loading && !initialLoading ? (
                        <>
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="text-sm"
                            ></FontAwesomeIcon>
                            <span>Update User</span>
                        </>
                    ) : (
                        <PulseLoader
                            color="white"
                            size={11}
                            margin={4}
                        ></PulseLoader>
                    )}
                </Button>
            </div>
        </main>
    );
};

export default EditUser;
