import React from 'react';

type Props = {
    className?: string;
};

const SkeletonLoader: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div
            className={`${props.className} bg-gray-200 rounded-lg animate-pulse`}
        ></div>
    );
};

export default SkeletonLoader;
