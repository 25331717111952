import React, { useState } from 'react';

import { createUser } from '../../../services/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from '../../formInput';
import Button from '../../button';
import {
    faArrowDown,
    faPlus,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';
import { useHistory } from 'react-router-dom';
import { auth } from '../../../services/firebase';

type Props = unknown;

const CreateUser: React.FunctionComponent<Props> = () => {
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');
    const [role, setRole] = useState<string>('user');

    const roleOptions = [
        {
            value: 'admin',
            label: 'Admin',
        },
        {
            value: 'manager',
            label: 'Manager',
        },
        {
            value: 'user',
            label: 'User',
        },
        {
            value: 'observer',
            label: 'Observer',
        },
    ];

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        } else if (name === 'userName') {
            setDisplayName(value);
        } else if (name === 'userPassword') {
            setPassword(value);
        } else if (name === 'userConfirmPassword') {
            setConfirmPassword(value);
        } else if (event.target.name === 'userRole') {
            console.log(event);
            setRole(event.target.value);
        }
    };

    const onSubmit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        email: string,
        displayName: string,
        password: string,
        confirmPassword: string,
        role: string,
    ) => {
        event.preventDefault();

        if (!email || !displayName || !password || !confirmPassword || !role) {
            setError('Please fill in all fields.');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords must match!');
            return;
        }

        setLoading(true);

        auth.currentUser
            ?.getIdToken(true)
            .then((token) =>
                createUser(
                    {
                        email: email,
                        displayName: displayName,
                        password: password,
                        role: role,
                    },
                    token,
                ),
            )
            .then(() => {
                setLoading(false);
                history.push('/users');
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return (
        <main className="container px-4 mx-auto flex flex-col bg-white pt-16 space-y-8 mb-8">
            <div className="flex flex-row">
                <h1 className="text-4xl flex-grow">Create User</h1>
            </div>
            <div
                className={`text-red-600 ${error && !loading ? '' : 'hidden'}`}
            >
                {error}
            </div>
            <form action="" className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="userEmail" className="text-sm mb-2">
                        Email address:
                    </label>
                    <FormInput
                        type="email"
                        name="userEmail"
                        id="userEmail"
                        value={email}
                        placeholder="joe@example.com"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="userName" className="text-sm mb-2">
                        Full name:
                    </label>
                    <FormInput
                        type="text"
                        name="userName"
                        id="userName"
                        value={displayName}
                        placeholder="Dr Joe Bloggs"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="userPassword" className="text-sm mb-2">
                        Password:
                    </label>
                    <FormInput
                        type="password"
                        name="userPassword"
                        id="userPassword"
                        value={password}
                        placeholder="••••••••"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label
                        htmlFor="userConfirmPassword"
                        className="text-sm mb-2"
                    >
                        Confirm password:
                    </label>
                    <FormInput
                        type="password"
                        name="userConfirmPassword"
                        id="userConfirmPassword"
                        value={confirmPassword}
                        placeholder="••••••••"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => onChangeHandler(event)}
                        disabled={loading}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="userRole" className="text-sm mb-2">
                        User account role:
                    </label>
                    <div className="w-full">
                        <select
                            value={role}
                            onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>,
                            ) => onChangeHandler(event)}
                            id="userRole"
                            name="userRole"
                            disabled={loading}
                            className="w-full rounded-full px-6 py-4 bg-white border hover:border-gray-300 focus:border-gray-300 outline-none transition duration-300 appearance-none"
                        >
                            {roleOptions.map((value, key) => (
                                <option key={key} value={value.value}>
                                    {value.label}
                                </option>
                            ))}
                        </select>
                        <span className="-ml-10 text-gray-400 pointer-events-none">
                            <FontAwesomeIcon
                                icon={faArrowDown}
                            ></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
                <div></div>
            </form>
            <div className="flex flex-row justify-end items-center space-x-4">
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                    type="button"
                    className=""
                    colorClass="red-600"
                    hoverColorClass="red-700"
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-md"
                    ></FontAwesomeIcon>
                    <span>Cancel</span>
                </Button>
                <Button
                    onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                        onSubmit(
                            event,
                            email,
                            displayName,
                            password,
                            confirmPassword,
                            role,
                        );
                    }}
                    type="button"
                    className=""
                >
                    {!loading ? (
                        <>
                            <FontAwesomeIcon
                                icon={faPlus}
                                className="text-sm"
                            ></FontAwesomeIcon>
                            <span>Add User</span>
                        </>
                    ) : (
                        <PulseLoader
                            color="white"
                            size={11}
                            margin={4}
                        ></PulseLoader>
                    )}
                </Button>
            </div>
        </main>
    );
};

export default CreateUser;
