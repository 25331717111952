import { faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { auth } from '../../../../services/firebase';
import Button from '../../../button';
import * as datapointService from '../../../../services/datapoint';
import { useHistory, useParams } from 'react-router-dom';

type Props = unknown;

const DeleteDatapoint: React.FunctionComponent<Props> = () => {
    const history = useHistory();

    const { entityId, datapointId } = useParams<{
        typeId: string;
        entityId: string;
        datapointId: string;
    }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const doDelete = () => {
        setLoading(true);

        auth.currentUser
            ?.getIdToken()
            .then((token) =>
                datapointService.deleteDatapoint(datapointId, entityId, token),
            )
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return (
        <main className="container flex flex-col px-4 pt-16 mx-auto mb-8 space-y-8 bg-white">
            <div className="flex flex-row">
                <h1 className="flex-grow text-4xl">Delete Datapoint</h1>
            </div>
            <div>Are you sure you want to delete this datapoint?</div>
            {error ? <p className="text-red-600"></p> : ''}
            <div className="flex flex-row items-center justify-end space-x-4">
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                    type="button"
                    className=""
                    colorClass="red-600"
                    hoverColorClass="red-700"
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-md"
                    ></FontAwesomeIcon>
                    <span>Cancel</span>
                </Button>
                <Button onClick={() => doDelete()} type="button" className="">
                    {!loading ? (
                        <>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="text-sm"
                            ></FontAwesomeIcon>
                            <span>Delete Datapoint</span>
                        </>
                    ) : (
                        <PulseLoader
                            color="white"
                            size={11}
                            margin={4}
                        ></PulseLoader>
                    )}
                </Button>
            </div>
        </main>
    );
};

export default DeleteDatapoint;
