import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ButtonLink from '../buttonLink';

type Props = unknown;

const NotFound: React.FunctionComponent<Props> = () => {
    return (
        <main className="container mx-auto px-4 flex flex-col items-center text-center bg-white py-24 space-y-8 mb-8">
            <h1 className="text-4xl">Page Not Found!</h1>
            <p>{`Nothing to see here...`}</p>
            <ButtonLink to="/">
                <FontAwesomeIcon
                    icon={faHome}
                    className="text-sm"
                ></FontAwesomeIcon>
                <span>Go Home</span>
            </ButtonLink>
        </main>
    );
};

export default NotFound;
