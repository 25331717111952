import axios from 'axios';

import apiRoot from './apiRoot';

const apiUrl = `${apiRoot}/statistics`;

export interface Statistics {
    tenant: {
        entities: {
            forever: Statistic[];
            lastWeek: Statistic[];
        };
        datapoints: {
            forever: Statistic[];
            lastWeek: Statistic[];
        };
    };
    user: {
        entities: {
            forever: Statistic[];
            lastWeek: Statistic[];
        };
        datapoints: {
            forever: Statistic[];
            lastWeek: Statistic[];
        };
    };
}

interface Statistic {
    count: number;
    _id: string;
}

const getStatistics = async (token: string): Promise<Statistics | null> => {
    try {
        const res = await axios.get(`${apiUrl}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return res.data;
    } catch (error) {
        return null;
    }
};

export { getStatistics };
