import React from 'react';
import Password from './password';
import SignOut from './signOut';
import UserInfo from './userInfo';

type Props = unknown;

const Account: React.FunctionComponent<Props> = () => {
    return (
        <main className="container mx-auto flex flex-col px-4 bg-white pt-16 mb-8">
            <h1 className="text-4xl mb-8">My Account</h1>
            <Password></Password>
            <UserInfo></UserInfo>
            <SignOut></SignOut>
        </main>
    );
};

export default Account;
