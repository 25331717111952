import React, { useContext } from 'react';
import { TenantContext } from '../../providers/tenantProvider';

type Props = {
    children: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    colorClass?: string;
    hoverColorClass?: string;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button: React.FunctionComponent<Props> = (props: Props) => {
    const tenant = useContext(TenantContext);

    return (
        <button
            onClick={props.onClick}
            type={props.type}
            className={`flex flex-row justify-center items-center space-x-2 px-6 py-4 rounded-full text-white font-bold focus:outline-none hover:shadow-md transition duration-300 ${
                props.colorClass
                    ? `bg-${props.colorClass}`
                    : `bg-${tenant?.colorScheme.mid}`
            } 
            ${
                props.hoverColorClass
                    ? `hover:bg-${props.hoverColorClass}`
                    : `hover:bg-${tenant?.colorScheme.dark}`
            } ${props.className}`}
        >
            {props.children}
        </button>
    );
};

export default Button;
