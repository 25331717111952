import Firebase from 'firebase';
import React, { Component, createContext } from 'react';
import AppLoader from '../components/appLoader';

import { auth } from '../services/firebase';

export const UserContext = createContext<AuthedUser | null>(null);

type Props = {
    children: React.ReactNode;
};

type AuthedUser = {
    uid: string | null;
    email: string | null;
    displayName: string | null;
    role: string;
    tenant: string;
};

class UserProvider extends Component<Props> {
    state: {
        user: AuthedUser | null;
        loading: boolean;
    } = {
        user: null,
        loading: true,
    };

    componentDidMount(): void {
        auth.onAuthStateChanged(async (userAuth: Firebase.User | null) => {
            let authedUser: AuthedUser | null = null;
            if (userAuth) {
                const tokenResult = await userAuth.getIdTokenResult(true);
                authedUser = {
                    uid: userAuth.uid,
                    email: userAuth.email,
                    displayName: userAuth?.displayName,
                    role: tokenResult.claims.role,
                    tenant: tokenResult.claims.tenant,
                };
            }
            this.setState({
                user: authedUser || null,
                loading: false,
            });
        });
        auth.onIdTokenChanged(async (userAuth: Firebase.User | null) => {
            let authedUser: AuthedUser | null = null;
            if (userAuth) {
                const tokenResult = await userAuth.getIdTokenResult(true);
                authedUser = {
                    uid: userAuth.uid,
                    email: userAuth.email,
                    displayName: userAuth?.displayName,
                    role: tokenResult.claims.role,
                    tenant: tokenResult.claims.tenant,
                };
            }
            this.setState({
                user: authedUser || null,
                loading: false,
            });
        });
    }

    render(): JSX.Element {
        return (
            <UserContext.Provider value={this.state.user}>
                {!this.state.loading ? (
                    this.props.children
                ) : (
                    <AppLoader></AppLoader>
                )}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
