import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TenantContext } from '../../providers/tenantProvider';

type Props = {
    children: React.ReactNode;
    to: string;
    colorClass?: string;
    hoverColorClass?: string;
    className?: string;
};

const ButtonLink: React.FunctionComponent<Props> = (props: Props) => {
    const tenant = useContext(TenantContext);

    return (
        <Link
            to={props.to}
            className={`flex flex-row items-center space-x-2 px-6 py-4 rounded-full text-white font-bold focus:outline-none hover:shadow-md transition duration-300 ${
                props.colorClass
                    ? `bg-${props.colorClass}`
                    : `bg-${tenant?.colorScheme.mid}`
            } 
            ${
                props.hoverColorClass
                    ? `hover:bg-${props.hoverColorClass}`
                    : `hover:bg-${tenant?.colorScheme.dark}`
            } ${props.className}`}
        >
            {props.children}
        </Link>
    );
};

export default ButtonLink;
