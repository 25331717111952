import { BehaviorSubject, Subscription } from 'rxjs';

import apiRoot from './apiRoot';

export type Tenant = {
    colorScheme: {
        dark: string;
        mid: string;
        light: string;
        hex: string;
    };
    name: string;
    authentication: {
        tenantId: string;
        providers: string[];
    };
    entities: [
        {
            _id: string;
            name: string;
            pluralName: string;
            fixedData: [
                {
                    variable: string;
                    label: string;
                    type: string;
                    required: boolean;
                    units?: string;
                    options?: string[];
                    inSummary?: boolean;
                    primaryKey?: boolean;
                },
            ];
            longData: [
                {
                    variable: string;
                    label: string;
                    type: string;
                    units?: string;
                    options?: string[];
                },
            ];
            forms: [
                {
                    name: string;
                    sections: [
                        {
                            name: string;
                            fields: [
                                {
                                    variable: string;
                                    calculation?: string;
                                    dependencies?: string[];
                                },
                            ];
                        },
                    ];
                },
            ];
        },
    ];
};

const tenant = new BehaviorSubject<Tenant | null>(null);

fetch(`${apiRoot}/tenants/byUrl/${window.location.hostname}`)
    .then((res) => res.json())
    .then((res) => {
        tenant.next(res);
    });

export const onTenantStateChanged = (
    callback: (a: Tenant | null) => void,
): Subscription => {
    return tenant.subscribe(callback);
};
