import React, { useContext, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../button';
import { faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';
import { useHistory, useParams } from 'react-router-dom';
import { auth } from '../../../services/firebase';
import { TenantContext } from '../../../providers/tenantProvider';
import * as entityService from '../../../services/entity';

type Props = unknown;

const DeleteEntity: React.FunctionComponent<Props> = () => {
    const tenant = useContext(TenantContext);
    const history = useHistory();

    const { typeId, entityId } = useParams<{
        typeId: string;
        entityId: string;
    }>();

    const entityType = tenant?.entities.find((entity) => entity._id === typeId);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const doDelete = () => {
        setLoading(true);

        auth.currentUser
            ?.getIdToken()
            .then((token) => entityService.deleteEntity(entityId, token))
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return (
        <main className="container px-4 mx-auto flex flex-col bg-white pt-16 space-y-8 mb-8">
            <div className="flex flex-row">
                <h1 className="text-4xl flex-grow">
                    Delete {entityType?.name}
                </h1>
            </div>
            <div>Are you sure you want to delete this {entityType?.name}?</div>
            {error ? <p className="text-red-600"></p> : ''}
            <div className="flex flex-row justify-end items-center space-x-4">
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                    type="button"
                    className=""
                    colorClass="red-600"
                    hoverColorClass="red-700"
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-md"
                    ></FontAwesomeIcon>
                    <span>Cancel</span>
                </Button>
                <Button onClick={() => doDelete()} type="button" className="">
                    {!loading ? (
                        <>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="text-sm"
                            ></FontAwesomeIcon>
                            <span>Delete {entityType?.name}</span>
                        </>
                    ) : (
                        <PulseLoader
                            color="white"
                            size={11}
                            margin={4}
                        ></PulseLoader>
                    )}
                </Button>
            </div>
        </main>
    );
};

export default DeleteEntity;
