import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { TenantContext } from '../../providers/tenantProvider';
import { UserContext } from '../../providers/userProvider';
import { auth } from '../../services/firebase';
import { getRecents, Recent } from '../../services/recent';
import { getStatistics, Statistics } from '../../services/statistics';
import ButtonLink from '../buttonLink';
import EntityList from '../entityType/entityList';
import SkeletonLoader from '../skeletonLoader';

type Props = unknown;

const Dashboard: React.FunctionComponent<Props> = () => {
    const tenant = useContext(TenantContext);
    const authedUser = useContext(UserContext);

    const [recentEntities, setRecentEntities] = useState<Recent[]>([]);
    const [recentLoading, setRecentLoading] = useState<
        {
            _id: string;
            loading: boolean;
        }[]
    >([]);

    const [statistics, setStatistics] = useState<Statistics>();
    const [statisticsLoading, setStatisticsLoading] = useState(true);

    useEffect(() => {
        tenant?.entities.map(async (entityType) => {
            setRecentLoading([
                ...recentLoading,
                { _id: entityType._id, loading: true },
            ]);
            auth.currentUser
                ?.getIdToken()
                .then((token) => getRecents(entityType._id, token))
                .then((recents) => {
                    if (recents) {
                        setRecentEntities([...recentEntities, recents]);
                        setRecentLoading(
                            recentLoading.map((i) =>
                                i._id === entityType._id
                                    ? { ...i, loading: false }
                                    : i,
                            ),
                        );
                    }
                });
        });

        auth.currentUser
            ?.getIdToken()
            .then((token) => getStatistics(token))
            .then((statistics) =>
                statistics ? setStatistics(statistics) : null,
            )
            .then(() => setStatisticsLoading(false));
    }, []);

    return (
        <main className="container mx-auto px-4 flex flex-col bg-white pt-16 space-y-12 mb-8">
            <div className="flex flex-row justify-between items-center mb-8">
                <h1 className="text-5xl">Hello, {authedUser?.displayName}!</h1>
                {tenant?.entities.map((entity, index) => (
                    <ButtonLink
                        key={index}
                        to={`/entityType/${entity._id}/create`}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            className="text-sm"
                        ></FontAwesomeIcon>
                        <span>Add {entity.name}</span>
                    </ButtonLink>
                ))}
            </div>
            <div className="grid grid-cols-4 gap-8 mb-12">
                {statisticsLoading ? (
                    <>
                        <SkeletonLoader className="h-40 p-8 rounded-3xl"></SkeletonLoader>
                        <SkeletonLoader className="h-40 p-8 rounded-3xl"></SkeletonLoader>
                        <SkeletonLoader className="h-40 p-8 rounded-3xl"></SkeletonLoader>
                        <SkeletonLoader className="h-40 p-8 rounded-3xl"></SkeletonLoader>
                    </>
                ) : (
                    tenant?.entities.map((entityType, index) => {
                        const tenantEntitiesLastWeek =
                            statistics?.tenant.entities.lastWeek.find(
                                (statistic) => statistic._id === entityType._id,
                            )?.count || 0;
                        const tenantDatapointsLastWeek =
                            statistics?.tenant.datapoints.lastWeek.find(
                                (statistic) => statistic._id === entityType._id,
                            )?.count || 0;
                        const tenantEntitiesForever =
                            statistics?.tenant.entities.forever.find(
                                (statistic) => statistic._id === entityType._id,
                            )?.count || 0;
                        const tenantDatapointsForever =
                            statistics?.tenant.datapoints.forever.find(
                                (statistic) => statistic._id === entityType._id,
                            )?.count || 0;

                        return (
                            <React.Fragment key={index}>
                                <div className="flex flex-col justify-center py-8 items-center rounded-3xl shadow-lg hover:shadow-xl transition duration-300">
                                    <span className="text-5xl mb-2">
                                        {tenantEntitiesLastWeek}
                                    </span>
                                    <span className="">
                                        {entityType.pluralName}
                                    </span>
                                    <span className="text-xs">this week</span>
                                </div>
                                <div className="flex flex-col justify-center py-8 items-center rounded-3xl shadow-lg hover:shadow-xl transition duration-300">
                                    <span className="text-5xl mb-2">
                                        {tenantDatapointsLastWeek}
                                    </span>
                                    <span className="">Datapoints</span>
                                    <span className="text-xs">this week</span>
                                </div>
                                <div className="flex flex-col justify-center py-8 items-center rounded-3xl shadow-lg hover:shadow-xl transition duration-300">
                                    <span className="text-5xl mb-2">
                                        {tenantEntitiesForever}
                                    </span>
                                    <span className="">
                                        {entityType.pluralName}
                                    </span>
                                    <span className="text-xs">
                                        added so far
                                    </span>
                                </div>
                                <div className="flex flex-col justify-center py-8 items-center rounded-3xl shadow-lg hover:shadow-xl transition duration-300">
                                    <span className="text-5xl mb-2">
                                        {tenantDatapointsForever}
                                    </span>
                                    <span className="">Datapoints</span>
                                    <span className="text-xs">created</span>
                                </div>
                            </React.Fragment>
                        );
                    })
                )}
            </div>
            {tenant?.entities.map((entityType, index) => (
                <div key={index} className="flex flex-col space-y-6">
                    <h2 className="text-2xl">Recent {entityType.pluralName}</h2>
                    {recentLoading.find((i) => i._id === entityType._id)
                        ?.loading ? (
                        <>
                            {[...Array(3)].map((val, index) => (
                                <div
                                    key={index}
                                    className="bg-gray-200 h-12 rounded-full animate-pulse"
                                ></div>
                            ))}
                        </>
                    ) : (
                        <EntityList
                            entities={
                                recentEntities.find(
                                    (recent) => recent.type === entityType._id,
                                )?.recentEntities || []
                            }
                            typeId={entityType._id}
                            filter={''}
                        ></EntityList>
                    )}
                </div>
            ))}
        </main>
    );
};

export default Dashboard;
