import axios from 'axios';
import { Entity } from './entity';

import apiRoot from './apiRoot';

const apiUrl = `${apiRoot}/recents`;

export interface Recent {
    _id?: string;
    type: string;
    tenant: string;
    recentEntities: Entity[];
}

const getRecents = async (
    type: string,
    token: string,
): Promise<Recent | null> => {
    try {
        const res = await axios.get(`${apiUrl}?entityType=${type}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return res.data;
    } catch (error) {
        return null;
    }
};

export { getRecents };
