import * as datapointService from '../../services/datapoint';
import * as entityService from '../../services/entity';

import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
    faArrowDown,
    faChartLine,
    faClipboardList,
    faPencilAlt,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';

import ButtonLink from '../buttonLink';
import DatapointList from './datapoint/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line } from 'react-chartjs-2';
import Modal from 'react-modal';
import SkeletonLoader from '../skeletonLoader';
import { TenantContext } from '../../providers/tenantProvider';
import { auth } from '../../services/firebase';
import { useParams } from 'react-router';

type Props = unknown;

const Entity: React.FunctionComponent<Props> = () => {
    const tenant = useContext(TenantContext);

    const [entity, setEntity] = useState<entityService.Entity>();
    const [datapoints, setDatapoints] = useState<
        datapointService.Datapoint[]
    >();
    const [latestData, setLatestData] = useState<
        { variable: string; value: string }[]
    >();
    const [loading, setLoading] = useState(true);

    const [currentForm, setCurrentForm] = useState(0);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [modalGraphData, setModalGraphData] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [modalGraphOptions, setModalGraphOptions] = useState<any>();

    const { typeId, entityId } = useParams<{
        typeId: string;
        entityId: string;
    }>();

    const entityType = tenant?.entities.find((entity) => entity._id === typeId);
    const primaryKeyVariable = entityType?.fixedData.find(
        (dataType) => dataType.primaryKey,
    )?.variable;

    useEffect(() => {
        const asyncFunc = async () => {
            const token = await auth.currentUser?.getIdToken();

            if (!token) return;

            const entityPromise = (async () => {
                const res = await entityService.getEntity(entityId, token);
                if (res) setEntity(res);
            })();

            const datapointPromise = (async () => {
                const res = await datapointService.getDatapoints(
                    entityId,
                    token,
                );

                console.log(res);

                if (!res || res.length < 1) return;

                setDatapoints(res);

                const latestList = entityType?.longData.flatMap((dataType) => {
                    const latest = res?.reduce((previous, current) => {
                        return current.longData.find(
                            (data) => data.variable === dataType.variable,
                        )?.value && current.date > previous.date
                            ? current
                            : previous;
                    });

                    return (
                        latest.longData.find(
                            (data) => data.variable === dataType.variable,
                        ) || []
                    );
                });

                setLatestData(latestList);
            })();

            await Promise.all([entityPromise, datapointPromise]);

            setLoading(false);
        };

        asyncFunc();
    }, []);

    const openModal = (variable: string) => {
        const dataType = entityType?.longData.find(
            (data) => data.variable === variable,
        );

        const data = {
            datasets: [
                {
                    label: dataType?.label,
                    backgroundColor: tenant?.colorScheme.hex + '22',
                    borderColor: tenant?.colorScheme.hex,
                    data:
                        datapoints?.flatMap((datapoint) => {
                            const value = datapoint.longData.find(
                                (data) => data.variable === variable,
                            )?.value;
                            return {
                                x: datapoint.date,
                                y: parseFloat(value),
                            };
                        }) || [],
                },
            ],
        };

        const options = {
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        time: {
                            unit: 'day',
                        },
                    },
                ],
                yAxes: [{ ticks: { beginAtZero: false } }],
            },
        };

        setModalTitle(dataType?.label || '');
        setModalGraphData(data);
        setModalGraphOptions(options);
        setModalIsOpen(true);
    };

    return (
        <>
            <main className="container flex flex-col px-4 pt-16 mx-auto mb-8 space-y-12 bg-white">
                {loading ? (
                    <>
                        <div className="flex flex-row items-center space-x-4">
                            <h1 className="flex-grow text-4xl">
                                {`View ${entityType?.name}`}
                            </h1>
                            <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                            <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                        </div>
                        <div className="flex flex-row">
                            <SkeletonLoader className="w-full h-36 rounded-3xl"></SkeletonLoader>
                        </div>
                        <div className="grid grid-cols-3 gap-8">
                            {[...Array(6)].map((val, index) => (
                                <SkeletonLoader
                                    key={index}
                                    className="h-16 rounded-full"
                                ></SkeletonLoader>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-row items-center">
                            <h1 className="flex-grow text-4xl">
                                {`${entityType?.name}: ${
                                    entity?.fixedData.find(
                                        (data) =>
                                            data.variable ===
                                            primaryKeyVariable,
                                    )?.value
                                }`}
                            </h1>
                            <div className="flex flex-row space-x-4">
                                <ButtonLink
                                    to={`/entityType/${typeId}/${entityId}/edit`}
                                    className={loading ? 'hidden' : ''}
                                    colorClass="gray-200"
                                    hoverColorClass="gray-300"
                                >
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        className="text-sm text-black"
                                    ></FontAwesomeIcon>
                                    <span className="text-black">
                                        Edit {entityType?.name}
                                    </span>
                                </ButtonLink>
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 p-8 transition duration-300 shadow-xl xl:grid-cols-4 rounded-3xl hover:shadow-2xl">
                            {entityType?.fixedData
                                .filter(
                                    (dataType) =>
                                        entity?.fixedData.find(
                                            (data) =>
                                                data.variable ===
                                                dataType.variable,
                                        )?.value,
                                )
                                .map((dataType, index) => (
                                    <div
                                        className="flex flex-col justify-start col-span-1"
                                        key={index}
                                    >
                                        <span className="text-sm italic">
                                            {dataType.label}
                                        </span>
                                        <span className="text-lg font-bold">
                                            {entity?.fixedData.find(
                                                (data) =>
                                                    data.variable ===
                                                    dataType.variable,
                                            )?.value || '-'}
                                        </span>
                                    </div>
                                ))}
                        </div>

                        <div className="flex flex-col">
                            <div className="flex flex-row items-center">
                                <h2 className="flex-grow text-2xl">{`Datapoints`}</h2>
                                <div className="flex flex-row space-x-4">
                                    <div className="w-64">
                                        <select
                                            value={currentForm}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLSelectElement>,
                                            ) =>
                                                setCurrentForm(
                                                    parseInt(
                                                        event.target.value,
                                                    ),
                                                )
                                            }
                                            disabled={loading}
                                            className="w-full px-6 py-4 transition duration-300 bg-white border rounded-full outline-none appearance-none w- hover:border-gray-300 focus:border-gray-300"
                                        >
                                            <option hidden disabled></option>
                                            {entityType?.forms.map(
                                                (value, key) => (
                                                    <option
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {value.name}
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                        <span className="-ml-10 text-gray-400 pointer-events-none">
                                            <FontAwesomeIcon
                                                icon={faArrowDown}
                                            ></FontAwesomeIcon>
                                        </span>
                                    </div>
                                    <ButtonLink
                                        to={`/entityType/${typeId}/${entityId}/datapoints/create?formIndex=${currentForm}`}
                                        className={loading ? 'hidden' : ''}
                                    >
                                        <FontAwesomeIcon
                                            icon={faClipboardList}
                                            className="text-sm"
                                        ></FontAwesomeIcon>
                                        <span>Launch Form</span>
                                    </ButtonLink>
                                </div>
                            </div>

                            <DatapointList
                                datapoints={datapoints}
                            ></DatapointList>
                        </div>

                        <div className="grid grid-cols-3 gap-4 p-4 xl:grid-cols-4">
                            {entityType?.longData.map((dataType, index) =>
                                latestData?.find(
                                    (data) =>
                                        data.variable === dataType.variable,
                                )?.value ? (
                                    <div
                                        className={`flex flex-col justify-start col-span-1 p-4 transition duration-300 hover:bg-gray-100 rounded-xl ${
                                            dataType.type === 'number' &&
                                            'cursor-pointer'
                                        }`}
                                        key={index}
                                        onClick={() =>
                                            dataType.type === 'number' &&
                                            openModal(dataType.variable)
                                        }
                                    >
                                        <span className="text-sm">
                                            {dataType.label}
                                            {dataType.type === 'number' && (
                                                <FontAwesomeIcon
                                                    icon={faChartLine}
                                                    className={`ml-2 text-${tenant?.colorScheme.mid}`}
                                                ></FontAwesomeIcon>
                                            )}
                                        </span>
                                        <div>
                                            <span className="text-lg">
                                                {
                                                    latestData?.find(
                                                        (data) =>
                                                            data.variable ===
                                                            dataType.variable,
                                                    )?.value
                                                }
                                            </span>
                                            <span className="text-sm">
                                                {' '}
                                                {dataType.units}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <Fragment key={index}></Fragment>
                                ),
                            )}
                        </div>
                    </>
                )}
            </main>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="container p-8 mx-auto mt-16 transition duration-300 bg-white shadow-xl outline-none hover:shadow-2xl rounded-3xl"
                ariaHideApp={false}
            >
                <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-between mb-8">
                        <h1 className="text-3xl">{modalTitle}</h1>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="text-2xl text-gray-300 transition duration-300 cursor-pointer hover:text-gray-400"
                            onClick={() => setModalIsOpen(false)}
                        ></FontAwesomeIcon>
                    </div>
                    <Line
                        data={modalGraphData}
                        options={modalGraphOptions}
                    ></Line>
                </div>
            </Modal>
        </>
    );
};

export default Entity;
