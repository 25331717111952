import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TenantContext } from '../../providers/tenantProvider';
import { Entity, getEntities } from '../../services/entity';
import { auth } from '../../services/firebase';
import ButtonLink from '../buttonLink';
import FormInput from '../formInput';
import SkeletonLoader from '../skeletonLoader';
import EntityList from './entityList';

type Props = unknown;

const EntityType: React.FunctionComponent<Props> = () => {
    const tenant = useContext(TenantContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [entities, setEntities] = useState<Entity[]>([]);

    const [search, setSearch] = useState('');

    const { typeId } = useParams<{ typeId: string }>();

    const entity = tenant?.entities.find((entity) => entity._id === typeId);

    useEffect(() => {
        auth.currentUser
            ?.getIdToken()
            .then((token) => getEntities(token, typeId))
            .then((res) => {
                if (res) {
                    setEntities(res);
                }
            })
            .then(() => setLoading(false));
    }, []);

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = event.currentTarget;

        if (name === 'searchField') {
            setSearch(value);
        }
    };

    return (
        <main className="container mx-auto px-4 flex flex-col bg-white pt-16 space-y-8 mb-8">
            {loading ? (
                <>
                    <div className="flex flex-row items-center space-x-4">
                        <h1 className="text-4xl flex-grow">{`${entity?.pluralName}`}</h1>
                        <SkeletonLoader className="h-16 w-72 rounded-full"></SkeletonLoader>
                        <SkeletonLoader className="h-16 w-40 rounded-full"></SkeletonLoader>
                    </div>
                    <div className="flex flex-col space-y-6">
                        {[...Array(3)].map((val, index) => (
                            <div
                                key={index}
                                className="bg-gray-200 h-12 rounded-full animate-pulse"
                            ></div>
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-row items-center">
                        <h1 className="text-4xl flex-grow">
                            {entity?.pluralName}
                        </h1>
                        <div className="flex flex-row space-x-4 items-center">
                            <FormInput
                                type="text"
                                name="searchField"
                                id="searchField"
                                value={search}
                                placeholder="Search..."
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => onChangeHandler(event)}
                                disabled={
                                    loading ||
                                    !entities ||
                                    entities.length === 0
                                }
                                required
                            />
                            <ButtonLink
                                to={`/entityType/${typeId}/create`}
                                className={loading ? 'hidden' : ''}
                            >
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-sm"
                                ></FontAwesomeIcon>
                                <span>New {entity?.name}</span>
                            </ButtonLink>
                        </div>
                    </div>

                    <EntityList
                        entities={entities}
                        typeId={typeId}
                        filter={search}
                    ></EntityList>
                </>
            )}
        </main>
    );
};

export default EntityType;
