import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { TenantContext } from '../../../providers/tenantProvider';

type Props = {
    route: { label: string; path: string; exact: boolean };
};

const NavbarMenuItem: React.FunctionComponent<Props> = (props: Props) => {
    const tenant = useContext(TenantContext);

    return (
        <NavLink
            to={props.route.path}
            exact={props.route.exact}
            className="py-8 border-b-2 border-white hover:border-gray-200 transition-border duration-300"
            activeClassName={`border-${tenant?.colorScheme.mid} hover:border-${tenant?.colorScheme.dark}`}
        >
            {props.route.label}
        </NavLink>
    );
};

export default NavbarMenuItem;
