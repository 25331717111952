import React from 'react';
import Account from '../account';
import Users from '../users';
import Dashboard from '../dashboard';
import CreateUser from '../users/create';
import DeleteUser from '../users/delete';
import EditUser from '../users/edit';
import EntityType from '../entityType';
import Entity from '../entity';
import EditEntity from '../entity/edit';
import DeleteEntity from '../entity/delete';
import CreateEntity from '../entity/create';
import CreateDatapoint from '../entity/datapoint/create';
import EditDatapoint from '../entity/datapoint/edit';
import DeleteDatapoint from '../entity/datapoint/delete';

interface Route {
    path: string;
    exact: boolean;
    component: JSX.Element;
    authorization?: {
        roles?: string[];
    };
}

export const routes: Route[] = [
    {
        path: '/',
        exact: true,
        component: <Dashboard></Dashboard>,
    },
    {
        path: '/entityType/:typeId',
        exact: true,
        component: <EntityType></EntityType>,
    },
    {
        path: '/entityType/:typeId/create',
        exact: true,
        component: <CreateEntity></CreateEntity>,
    },
    {
        path: '/entityType/:typeId/:entityId',
        exact: true,
        component: <Entity></Entity>,
    },
    {
        path: '/entityType/:typeId/:entityId/edit',
        exact: true,
        component: <EditEntity></EditEntity>,
    },
    {
        path: '/entityType/:typeId/:entityId/delete',
        exact: true,
        component: <DeleteEntity></DeleteEntity>,
    },
    {
        path: '/entityType/:typeId/:entityId/datapoints/:datapointId/edit',
        exact: true,
        component: <EditDatapoint></EditDatapoint>,
    },
    {
        path: '/entityType/:typeId/:entityId/datapoints/:datapointId/delete',
        exact: true,
        component: <DeleteDatapoint></DeleteDatapoint>,
    },
    {
        path: '/entityType/:typeId/:entityId/datapoints/create',
        exact: true,
        component: <CreateDatapoint></CreateDatapoint>,
    },
    {
        path: '/users',
        exact: true,
        component: <Users></Users>,
        authorization: {
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/users/create',
        exact: true,
        component: <CreateUser></CreateUser>,
        authorization: {
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/users/:id/delete',
        exact: true,
        component: <DeleteUser></DeleteUser>,
        authorization: {
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/users/:id',
        exact: true,
        component: <EditUser></EditUser>,
        authorization: {
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/account',
        exact: true,
        component: <Account></Account>,
    },
];

export const getPermittedRoutes = (userRole: string): Route[] => {
    return routes.filter(
        (route) =>
            !route.authorization?.roles ||
            route.authorization?.roles.includes(userRole),
    );
};
