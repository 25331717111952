import Firebase from 'firebase/app';
import 'firebase/auth';
import { onTenantStateChanged } from './tenant';

const config = {
    apiKey: 'AIzaSyB5lg55NhvG2LR_LctYvl5T3obRKyKsg_4',
    authDomain: 'hepian-registry.firebaseapp.com',
};

if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
}

onTenantStateChanged((tenant) => {
    if (tenant?.authentication.tenantId) {
        auth.tenantId = tenant.authentication.tenantId;
    }
});

export const signOut = async (): Promise<void> => {
    await auth.signOut();
};

export const auth = Firebase.auth();
