import React, { Component, createContext } from 'react';
import AppLoader from '../components/appLoader';

import { onTenantStateChanged, Tenant } from '../services/tenant';

export const TenantContext = createContext<Tenant | null>(null);

type Props = {
    children: React.ReactNode;
};

class TenantProvider extends Component<Props> {
    state = {
        tenant: null,
    };

    componentDidMount(): void {
        onTenantStateChanged((tenant) => this.setState({ tenant: tenant }));
    }

    render(): JSX.Element {
        return (
            <TenantContext.Provider value={this.state.tenant}>
                {this.state.tenant ? (
                    this.props.children
                ) : (
                    <AppLoader></AppLoader>
                )}
            </TenantContext.Provider>
        );
    }
}

export default TenantProvider;
