import axios from 'axios';

import apiRoot from './apiRoot';

const apiUrl = `${apiRoot}/entities`;

export interface Entity {
    _id?: string;
    type: string;
    fixedData: {
        variable: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
    }[];
}

const getEntity = async (
    entityId: string,
    token: string,
): Promise<Entity | null> => {
    try {
        const res = await axios.get(`${apiUrl}/${entityId}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return res.data;
    } catch (error) {
        return null;
    }
};

const getEntities = async (
    token: string,
    type: string,
): Promise<Entity[] | null> => {
    try {
        const res = await axios.get(`${apiUrl}?type=${type}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return res.data;
    } catch (error) {
        return null;
    }
};

const deleteEntity = async (
    entityId: string,
    token: string,
): Promise<true | null> => {
    try {
        await axios.delete(`${apiUrl}/${entityId}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return true;
    } catch (error) {
        return null;
    }
};

const createEntity = async (
    entity: Entity,
    token: string,
): Promise<true | null> => {
    try {
        await axios.post(
            `${apiUrl}`,
            {
                ...entity,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        );
        return true;
    } catch (error) {
        console.log(error.response);
        return null;
    }
};

const updateEntity = async (
    entityId: string,
    entity: Partial<Entity>,
    token: string,
): Promise<true | null> => {
    try {
        await axios.patch(
            `${apiUrl}/${entityId}`,
            {
                ...entity,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        );
        return true;
    } catch (error) {
        return null;
    }
};

export { createEntity, getEntity, getEntities, updateEntity, deleteEntity };
