import React, { useState } from 'react';

import { deleteUser } from '../../../services/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../button';
import { faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';
import { useHistory, useParams } from 'react-router-dom';
import { auth } from '../../../services/firebase';

type Props = unknown;

const DeleteUser: React.FunctionComponent<Props> = () => {
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const doDelete = () => {
        setLoading(true);

        auth.currentUser
            ?.getIdToken(true)
            .then((token) => deleteUser(id, token))
            .then(() => {
                setLoading(false);
                history.push('/users');
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return (
        <main className="container px-4 mx-auto flex flex-col bg-white pt-16 space-y-8 mb-8">
            <div className="flex flex-row">
                <h1 className="text-4xl flex-grow">Delete User</h1>
            </div>
            <div>Are you sure you want to delete this user?</div>
            {error ? <p className="text-red-600"></p> : ''}
            <div className="flex flex-row justify-end items-center space-x-4">
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                    type="button"
                    className=""
                    colorClass="red-600"
                    hoverColorClass="red-700"
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-md"
                    ></FontAwesomeIcon>
                    <span>Cancel</span>
                </Button>
                <Button onClick={() => doDelete()} type="button" className="">
                    {!loading ? (
                        <>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="text-sm"
                            ></FontAwesomeIcon>
                            <span>Delete User</span>
                        </>
                    ) : (
                        <PulseLoader
                            color="white"
                            size={11}
                            margin={4}
                        ></PulseLoader>
                    )}
                </Button>
            </div>
        </main>
    );
};

export default DeleteUser;
