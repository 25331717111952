import { Link, useHistory } from 'react-router-dom';
import React, { useContext } from 'react';
import {
    faPencilAlt,
    faStopwatch,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import { Datapoint } from '../../../../services/datapoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TenantContext } from '../../../../providers/tenantProvider';

type Props = {
    datapoints?: Datapoint[];
};

const DatapointList: React.FunctionComponent<Props> = (props: Props) => {
    const tenant = useContext(TenantContext);
    const history = useHistory();

    if (!props.datapoints || props.datapoints?.length === 0)
        return (
            <p className="mt-12 italic text-center">
                There are no datapoints to display yet.
            </p>
        );

    return (
        <table className="w-full text-left border-b table-fixed">
            <thead>
                <tr className="border-b">
                    <th className="w-1/12 p-4"></th>
                    <th className="p-4">Date</th>
                    <th className="p-4">Form Name</th>
                    <th className="w-2/12 p-4"></th>
                </tr>
            </thead>
            <tbody className="">
                {props.datapoints
                    ?.sort(
                        (a, b) =>
                            new Date(b.date).getTime() -
                            new Date(a.date).getTime(),
                    )
                    .map((datapoint, index) => (
                        <tr
                            className="leading-5 transition duration-300 cursor-pointer hover:bg-gray-50"
                            key={index}
                            onClick={() =>
                                history.push(
                                    `/entityType/${datapoint.type}/${datapoint.entity}/datapoints/${datapoint._id}/edit`,
                                )
                            }
                        >
                            <td className="">
                                <div className="flex flex-row items-center justify-center w-12 h-12 text-xl text-gray-300">
                                    <FontAwesomeIcon icon={faStopwatch} />
                                </div>
                            </td>
                            <td className="p-4">
                                {new Date(
                                    datapoint.date.toString(),
                                ).toLocaleDateString()}
                            </td>
                            <td className="p-4">
                                {
                                    tenant?.entities.find(
                                        (a) => a._id === datapoint.type,
                                    )?.forms[datapoint.createdByFormId].name
                                }
                            </td>
                            <td className="flex flex-row justify-end p-4 space-x-2">
                                <Link
                                    to={`/entityType/${datapoint.type}/${datapoint.entity}/datapoints/${datapoint._id}/edit`}
                                    onClick={(
                                        e: React.MouseEvent<
                                            HTMLAnchorElement,
                                            MouseEvent
                                        >,
                                    ) => e.stopPropagation()}
                                >
                                    <div
                                        className={`rounded-full w-8 h-8 flex flex-row items-center justify-center text-sm text-${tenant?.colorScheme.mid} hover:text-${tenant?.colorScheme.dark} hover:bg-white hover:shadow-lg transition duration-300`}
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </div>
                                </Link>
                                <Link
                                    to={`/entityType/${datapoint.type}/${datapoint.entity}/datapoints/${datapoint._id}/delete`}
                                    onClick={(
                                        e: React.MouseEvent<
                                            HTMLAnchorElement,
                                            MouseEvent
                                        >,
                                    ) => e.stopPropagation()}
                                >
                                    <div
                                        className={`rounded-full w-8 h-8 flex flex-row items-center justify-center text-sm text-${tenant?.colorScheme.mid} hover:text-${tenant?.colorScheme.dark} hover:bg-white hover:shadow-lg transition duration-300`}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </div>
                                </Link>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};

export default DatapointList;
