import axios from 'axios';

import apiRoot from './apiRoot';

const apiUrl = `${apiRoot}/entities`;

export interface Datapoint {
    _id?: string;
    entity: string;
    type: string;
    createdByFormId: number;
    date: Date;
    longData: {
        variable: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
    }[];
}

const getDatapoint = async (
    datapointId: string,
    entityId: string,
    token: string,
): Promise<Datapoint | null> => {
    try {
        const res = await axios.get(
            `${apiUrl}/${entityId}/datapoints/${datapointId}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        );
        return res.data;
    } catch (error) {
        return null;
    }
};

const getDatapoints = async (
    entityId: string,
    token: string,
): Promise<Datapoint[] | null> => {
    try {
        const res = await axios.get(`${apiUrl}/${entityId}/datapoints`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return res.data;
    } catch (error) {
        return null;
    }
};

const deleteDatapoint = async (
    datapointId: string,
    entityId: string,
    token: string,
): Promise<true | null> => {
    try {
        await axios.delete(`${apiUrl}/${entityId}/datapoints/${datapointId}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return true;
    } catch (error) {
        return null;
    }
};

const createDatapoint = async (
    entityId: string,
    datapoint: Datapoint,
    token: string,
): Promise<true | null> => {
    try {
        await axios.post(
            `${apiUrl}/${entityId}/datapoints`,
            {
                ...datapoint,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        );
        return true;
    } catch (error) {
        console.log(error.response);
        return null;
    }
};

const updateDatapoint = async (
    datapointId: string,
    entityId: string,
    datapoint: Partial<Datapoint>,
    token: string,
): Promise<true | null> => {
    try {
        await axios.patch(
            `${apiUrl}/${entityId}/datapoints/${datapointId}`,
            {
                ...datapoint,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        );
        return true;
    } catch (error) {
        return null;
    }
};

export {
    createDatapoint,
    getDatapoint,
    getDatapoints,
    updateDatapoint,
    deleteDatapoint,
};
