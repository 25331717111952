import {
    faDatabase,
    faPencilAlt,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { TenantContext } from '../../../providers/tenantProvider';
import { Entity } from '../../../services/entity';

type Props = {
    entities: Entity[];
    typeId: string;
    filter: string;
};

const EntityList: React.FunctionComponent<Props> = (props: Props) => {
    const tenant = useContext(TenantContext);
    const history = useHistory();

    const typeId = props.typeId;

    const entityType = tenant?.entities.find((entity) => entity._id === typeId);

    const [index, setIndex] = useState<{ indexString: string; _id: string }[]>(
        [],
    );

    useEffect(() => {
        if (!entityType) return;

        const searchableDataTypes: string[] = entityType?.fixedData
            .filter((dataType) => dataType.inSummary)
            .map((dataType) => dataType.variable);

        setIndex(
            props.entities.map((entity) => {
                return {
                    indexString: entity.fixedData
                        .filter((data) =>
                            searchableDataTypes.includes(data.variable),
                        )
                        .map((data) => data.value)
                        .join('#'),
                    _id: entity._id || '',
                };
            }),
        );
    }, []);

    const getFilteredEntities = (entities: Entity[], filterString: string) => {
        if (!filterString || !index) return entities;

        const filteredIds = index
            .filter((indexItem) => indexItem.indexString.includes(filterString))
            .map((indexItem) => indexItem._id);

        return entities.filter((entity) =>
            filteredIds.includes(entity._id || ''),
        );
    };

    if (getFilteredEntities(props.entities, props.filter).length === 0)
        return (
            <p className="mt-12 text-center italic">
                There are no {entityType?.pluralName.toLocaleLowerCase()} to
                display.
            </p>
        );

    return (
        <article className="flex flex-col">
            <table className="table-fixed w-full text-left border-b">
                <thead>
                    <tr className="border-b">
                        <th className="w-1/12 p-4"></th>
                        {entityType?.fixedData
                            .filter((value) => value.inSummary)
                            .map((variable, index) => (
                                <th className="p-4" key={index}>
                                    {variable.label}
                                </th>
                            ))}
                        <th className="w-2/12 p-4"></th>
                    </tr>
                </thead>
                <tbody className="">
                    {getFilteredEntities(props.entities, props.filter).map(
                        (entity, index) => (
                            <tr
                                className="hover:bg-gray-50 transition duration-300 leading-5 cursor-pointer"
                                key={index}
                                onClick={() =>
                                    history.push(
                                        `/entityType/${typeId}/${entity._id}`,
                                    )
                                }
                            >
                                <td className="">
                                    <div className="w-12 h-12 flex flex-row items-center justify-center text-xl text-gray-300">
                                        <FontAwesomeIcon icon={faDatabase} />
                                    </div>
                                </td>
                                {entityType?.fixedData
                                    .filter((value) => value.inSummary)
                                    .map((variable, index) => (
                                        <td className="p-4" key={index}>
                                            {
                                                entity.fixedData.find(
                                                    (data) =>
                                                        data.variable ===
                                                        variable.variable,
                                                )?.value
                                            }
                                        </td>
                                    ))}
                                <td className="flex flex-row justify-end space-x-2 p-4">
                                    <Link
                                        to={`/entityType/${typeId}/${entity._id}/edit`}
                                        onClick={(
                                            e: React.MouseEvent<
                                                HTMLAnchorElement,
                                                MouseEvent
                                            >,
                                        ) => e.stopPropagation()}
                                    >
                                        <div
                                            className={`rounded-full w-8 h-8 flex flex-row items-center justify-center text-sm text-${tenant?.colorScheme.mid} hover:text-${tenant?.colorScheme.dark} hover:bg-white hover:shadow-lg transition duration-300`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                            />
                                        </div>
                                    </Link>
                                    <Link
                                        to={`/entityType/${typeId}/${entity._id}/delete`}
                                        onClick={(
                                            e: React.MouseEvent<
                                                HTMLAnchorElement,
                                                MouseEvent
                                            >,
                                        ) => e.stopPropagation()}
                                    >
                                        <div
                                            className={`rounded-full w-8 h-8 flex flex-row items-center justify-center text-sm text-${tenant?.colorScheme.mid} hover:text-${tenant?.colorScheme.dark} hover:bg-white hover:shadow-lg transition duration-300`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                            />
                                        </div>
                                    </Link>
                                </td>
                            </tr>
                        ),
                    )}
                </tbody>
            </table>
        </article>
    );
};

export default EntityList;
