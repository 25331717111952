import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TenantContext } from '../../providers/tenantProvider';
import { UserContext } from '../../providers/userProvider';
import NavbarMenuItem from './navbarMenuItem';

type Props = {
    logo: string;
    routes: Array<{ label: string; path: string; exact: boolean }>;
};

const Navbar: React.FunctionComponent<Props> = (props: Props) => {
    const authedUser = useContext(UserContext);
    const tenant = useContext(TenantContext);

    return (
        <header className="sticky top-0 border-b bg-white">
            <div className="container mx-auto px-4 flex flex-row items-center justify-between">
                <div
                    className={`text-xl font-bold text-${tenant?.colorScheme.mid}`}
                >
                    {tenant?.name}
                </div>
                <nav className="flex flex-row space-x-8">
                    <ul className="flex flex-row space-x-8">
                        {props.routes.map((route, index) => {
                            return <NavbarMenuItem key={index} route={route} />;
                        })}
                    </ul>
                </nav>
                <Link
                    className="flex flex-row flex-shrink-0 items-center justify-left space-x-4 hover:bg-gray-100 p-2 rounded-lg transition duration-300 cursor-pointer"
                    to="/account"
                >
                    <div className="flex flex-col items-end">
                        <span className="font-bold text-sm">
                            {authedUser?.displayName || 'Anonymous User'}
                        </span>
                        <span className="text-sm text-gray-500">
                            Manage Account
                        </span>
                    </div>
                    <div className="rounded-full w-12 h-12 flex flex-row items-center justify-center bg-gray-200 text-xl text-gray-400">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                </Link>
            </div>
        </header>
    );
};

export default Navbar;
