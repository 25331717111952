import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

type Props = unknown;

const AppLoader: React.FunctionComponent<Props> = () => {
    return (
        <div className="flex flex-col justify-center items-center min-h-screen">
            <div
                className="flex flex-col items-center justify-center rounded-full h-24 w-24 shadow-xl hover:shadow-2xl transition duration-300"
                data-testid="spinnerContainer"
            >
                <PulseLoader color="gray" size={15} margin={4}></PulseLoader>
            </div>
        </div>
    );
};

export default AppLoader;
