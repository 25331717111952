import * as entityService from '../../../services/entity';

import React, { useContext, useEffect, useState } from 'react';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router';

import Button from '../../button';
import DataField from '../../dataField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PulseLoader } from 'react-spinners';
import SkeletonLoader from '../../skeletonLoader';
import { TenantContext } from '../../../providers/tenantProvider';
import { auth } from '../../../services/firebase';

type Props = unknown;

const CreateEntity: React.FunctionComponent<Props> = () => {
    const tenant = useContext(TenantContext);
    const history = useHistory();

    const [entity, setEntity] = useState<entityService.Entity>();

    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const { typeId } = useParams<{
        typeId: string;
        entityId: string;
    }>();

    const entityType = tenant?.entities.find((entity) => entity._id === typeId);

    useEffect(() => {
        if (!entityType) return;
        const newEntity: entityService.Entity = {
            type: typeId,
            fixedData: entityType.fixedData.map((dataType) => {
                return { variable: dataType.variable, value: '' };
            }),
        };

        setEntity(newEntity);
        setInitialLoading(false);
    }, []);

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        if (!entity) return;

        const { name, value } = event.currentTarget || event.target;

        const fieldIndex = entity.fixedData?.findIndex(
            (data) => data.variable === name,
        );

        const newEntity = { ...entity };
        newEntity.fixedData[fieldIndex].value = value;

        setEntity(newEntity);
    };

    const onSubmit = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        entity: entityService.Entity | undefined,
    ) => {
        if (!entity) return;

        setLoading(true);

        auth.currentUser
            ?.getIdToken()
            .then((token) => entityService.createEntity(entity, token))
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <main className="container flex flex-col px-4 pt-16 mx-auto mb-8 space-y-8 bg-white">
            <div className="flex flex-row items-center">
                <h1 className="flex-grow text-4xl">
                    {`Create ${entityType?.name}`}
                </h1>
            </div>
            {initialLoading ? (
                <>
                    <div className="grid grid-cols-2 gap-8">
                        {[...Array(6)].map((val, index) => (
                            <SkeletonLoader
                                key={index}
                                className="h-16 rounded-full"
                            ></SkeletonLoader>
                        ))}
                    </div>
                    <div className="flex flex-row items-center justify-end space-x-4">
                        <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                        <SkeletonLoader className="w-48 h-16 rounded-full"></SkeletonLoader>
                    </div>
                </>
            ) : (
                <>
                    <form className="grid grid-cols-2 gap-8">
                        {entityType?.fixedData.map((dataType, index) => (
                            <div className="flex flex-col" key={index}>
                                <label
                                    htmlFor={dataType.variable}
                                    className="mb-2 text-sm"
                                >
                                    {dataType.label}
                                </label>
                                {initialLoading ? (
                                    <div className="h-16 bg-gray-200 rounded-full animate-pulse"></div>
                                ) : (
                                    <DataField
                                        name={dataType.variable}
                                        id={dataType.variable}
                                        value={
                                            entity?.fixedData.find(
                                                (data) =>
                                                    data.variable ===
                                                    dataType.variable,
                                            )?.value || ''
                                        }
                                        placeholder={dataType.label}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLSelectElement
                                            >,
                                        ) => onChangeHandler(event)}
                                        disabled={loading}
                                        dataType={dataType.type}
                                        typeOptions={dataType.options}
                                        required={dataType.required}
                                    />
                                )}
                            </div>
                        ))}
                    </form>
                    <div className="flex flex-row items-center justify-end space-x-4">
                        <Button
                            onClick={() => {
                                history.goBack();
                            }}
                            type="button"
                            className=""
                            colorClass="red-600"
                            hoverColorClass="red-700"
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="text-md"
                            ></FontAwesomeIcon>
                            <span>Cancel</span>
                        </Button>
                        <Button
                            onClick={(
                                event: React.MouseEvent<
                                    HTMLButtonElement,
                                    MouseEvent
                                >,
                            ) => {
                                onSubmit(event, entity);
                            }}
                            type="button"
                            className=""
                        >
                            {!loading && !initialLoading ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="text-sm"
                                    ></FontAwesomeIcon>
                                    <span>Create {entityType?.name}</span>
                                </>
                            ) : (
                                <PulseLoader
                                    color="white"
                                    size={11}
                                    margin={4}
                                ></PulseLoader>
                            )}
                        </Button>
                    </div>
                </>
            )}
        </main>
    );
};

export default CreateEntity;
